import React from "react";
import { Container } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'


export default function Footer() {
    return (
        <AppBar elevation={0} position="static" style={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor:"white", maxHeight:60  }}>
            <Container maxWidth="md">
                <Toolbar style={{backgroundColor:"white"}}>
                    <Typography variant="h5" color="inherit" style={{flexGrow:1}}>
                        <Link href="/useConditions" style={{ color: "black" }}>© CotCotCodec 2025 - Conditions d'utilisation</Link>
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
